
// import scripts
// eslint-disable-next-line import/no-extraneous-dependencies
import $ from 'jquery';
//import { toggleControls } from './includes/toggle-controls';
// import { moviesSlider } from './includes/movies-slider';
import { hamburger } from './includes/hamburger';
import { getCookie } from './includes/cookie';
import { navigationActiveClass } from './includes/navigation';
import { setURL } from './includes/url';

const Ourbeers = {
	onReady() {

		$( '.beer--item button' ).on( 'click', function( event ) {

			$( event.target ).closest( '.beer--item' ).find( '.ourBeersPopup' ).toggleClass( '-visible' );

		} );

		$( '.ourBeersPopup' ).on( 'click', function( event ) {

			$( event.target ).closest( '.ourBeersPopup' ).toggleClass( '-visible' );

		} );

	},
};

$( document ).ready( Ourbeers.onReady );
