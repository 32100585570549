
// products-slider.js
import { products } from './products';

export const productsSlider = () => {

	const btnNext = document.querySelector( '.products-slider-btn-next' );
	const btnPrev = document.querySelector( '.products-slider-btn-prev' );

	const sliderName = document.querySelector( '.products-slider-name' );

	const sliderImgContainer = document.querySelector( '.products-slider-images' );
	const sliderImg = document.createElement( 'img' );

	const productsName = document.querySelector( '.products-name' );

	const productsImgContainer = document.querySelector( '.products-images' );
	const productsImg = document.createElement( 'img' );

	// eslint-disable-next-line @wordpress/no-unused-vars-before-return
	const productsAlcohol = document.querySelector( '.products-alcohol' );
	const productsIngredients = document.querySelector( '.products-ingredients' );
	const productsEnergyValue = document.querySelector( '.products-energy-value' );
	const productsCaption = document.querySelector( 'p.products-description' );
	const productsExtract = document.querySelector( '.extract-js' );

	// default index

	let sliderIndex = 1;
	let productsIndex = 0;

	// default slider values

	if ( ! sliderName ) {

		return false;

	}

	sliderName.innerHTML = products[ sliderIndex ].name;

	sliderImg.src = products[ sliderIndex ].img;
	sliderImgContainer.appendChild( sliderImg );

	// default products values

	productsName.innerHTML = products[ productsIndex ].name;

	productsImg.src = products[ productsIndex ].img;
	productsImgContainer.appendChild( productsImg );

	productsAlcohol.innerHTML = products[ productsIndex ].alcohol;
	productsIngredients.innerHTML = products[ productsIndex ].ingredients;
	productsEnergyValue.innerHTML = products[ productsIndex ].energyValue;
	productsCaption.innerHTML = products[ productsIndex ].caption;

	if ( products[ productsIndex ].showExtract ) {

		productsExtract.style.display = 'block';

	} else {

		productsExtract.style.display = 'none';

	}

	// next btn
	btnNext.addEventListener( 'click', function() {

		if ( sliderIndex === products.length - 1 ) {

			sliderIndex = 0;

		} else {

			sliderIndex += 1;

		}

		sliderName.innerHTML = products[ sliderIndex ].name;
		sliderImg.src = products[ sliderIndex ].img;

		if ( productsIndex === products.length - 1 ) {

			productsIndex = 0;

		} else {

			productsIndex += 1;

		}

		productsName.innerHTML = products[ productsIndex ].name;
		productsImg.src = products[ productsIndex ].img;
		productsAlcohol.innerHTML = products[ productsIndex ].alcohol;
		productsIngredients.innerHTML = products[ productsIndex ].ingredients;
		productsEnergyValue.innerHTML = products[ productsIndex ].energyValue;
		productsCaption.innerHTML = products[ productsIndex ].caption;

		if ( products[ productsIndex ].showExtract ) {

			productsExtract.style.display = 'block';

		} else {

			productsExtract.style.display = 'none';

		}

	} );

	// prev btn
	btnPrev.addEventListener( 'click', function() {

		if ( sliderIndex === 0 ) {

			sliderIndex = products.length - 1;

		} else {

			sliderIndex -= 1;

		}

		sliderName.innerHTML = products[ sliderIndex ].name;
		sliderImg.src = products[ sliderIndex ].img;

		if ( productsIndex === 0 ) {

			productsIndex = products.length - 1;

		} else {

			productsIndex -= 1;

		}

		productsName.innerHTML = products[ productsIndex ].name;
		productsImg.src = products[ productsIndex ].img;
		productsAlcohol.innerHTML = products[ productsIndex ].alcohol;
		productsIngredients.innerHTML = products[ productsIndex ].ingredients;
		productsEnergyValue.innerHTML = products[ productsIndex ].energyValue;
		productsCaption.innerHTML = products[ productsIndex ].caption;

		if ( products[ productsIndex ].showExtract ) {

			productsExtract.style.display = 'block';

		} else {

			productsExtract.style.display = 'none';

		}

	} );

};
