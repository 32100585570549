
// eslint-disable-next-line require-jsdoc
export const setURL = () => {

	const url = window.location.href;
	localStorage.setItem( 'url', url );

};

//export const getURL = () => localStorage.getItem('url');
