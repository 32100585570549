
// products.js

const bottleImg = '/wp-content/themes/harnasxosp/src/images/product-1.png';
const tinImg = '/wp-content/themes/harnasxosp/src/images/product-2.png';
const fourPackImg = '/wp-content/themes/harnasxosp/src/images/product-3.png';
const sixPackImg = '/wp-content/themes/harnasxosp/src/images/product-4.png';
const eightPackImg = '/wp-content/themes/harnasxosp/src/images/product-5.png';
const twelvePackImg = '/wp-content/themes/harnasxosp/src/images/product-6.png';
const lodoherbataTinImg = '/wp-content/themes/harnasxosp/src/images/product-7.png';
const energetykTinImg = '/wp-content/themes/harnasxosp/src/images/product-8.png';

// eslint-disable-next-line import/no-unresolved
// import bottleImg from '/wp-content/themes/harnasxosp/src/images/product-1.png';
// eslint-disable-next-line import/no-unresolved
// import tinImg from '/wp-content/themes/harnasxosp/src/images/product-2.png';
// // eslint-disable-next-line import/no-unresolved
// import fourPackImg from '/wp-content/themes/harnasxosp/src/images/product-3.png';
// // eslint-disable-next-line import/no-unresolved
// import sixPackImg from '/wp-content/themes/harnasxosp/src/images/product-4.png';
// // eslint-disable-next-line import/no-unresolved
// import eightPackImg from '/wp-content/themes/harnasxosp/src/images/product-5.png';
// // eslint-disable-next-line import/no-unresolved
// import twelvePackImg from '/wp-content/themes/harnasxosp/src/images/product-6.png';
// // eslint-disable-next-line import/no-unresolved
// import lodoherbataTinImg from '/wp-content/themes/harnasxosp/src/images/product-7.png';
// // eslint-disable-next-line import/no-unresolved
// import energetykTinImg from '/wp-content/themes/harnasxosp/src/images/product-8.png';

export const products = [
	// {
	// 	name: 'Harnaś Piwny Energetyk 0,0%',
	// 	img: energetykTinImg,
	// 	alcohol: '0,0%',
	// 	ingredients: 'Składniki <span class="line"></span> woda, słód <span class="accent">jęczmienny</span>, sok jabłkowy z zagęszczonego soku (20 %), cukier, aromaty, tauryna (0,2 %), chmiel, kwas (kwas cytrynowy), kofeina (32 mg/100 ml).',
	// 	energyValue: 'Wartość odżywcza w 100 ml <span class="line"></span> Wartość energetyczna 134 kJ/32 kcal.<br>Tłuszcz - 0g<br>W tym kwasy tłuszczowe nasycone - 0g<br>Węglowodany - 6,7g<br>w tym cukry - 4,1g<br>Białko < 0,5g<br>Sól - 0g',
	// 	caption: 'Sprzedawany jest w puszkach 0,5 l. Dostępny w opakowaniu zbiorczym (4 x puszka 0,5 l).',
	// 	showExtract: false,
	// },
	{
		name: 'Harnaś LodoHerbata',
		img: lodoherbataTinImg,
		alcohol: '4,6%',
		ingredients: 'Składniki <span class="line"></span> piwo (woda, słód <span class="accent">jęczmienny</span>, syrop z <span class="accent">pszenicy</span> i słodu <span class="accent">jęczmiennego</span>, <span class="accent">jęczmień</span>, chmiel), napój o smaku herbaty brzoskwiniowej (woda, cukier, dwutlenek węgla, sok brzoskwiniowy z zagęszczonego soku brzoskwiniowego (0,1%), ekstrakt słodu <span class="accent">jęczmiennego</span>, naturalny aromat, kwas: kwas cytrynowy, naturalny aromat herbaty z innymi naturalnymi aromatami).',
		energyValue: 'Wartość odżywcza w 100 ml <span class="line"></span> Wartość energetyczna 160 kJ/40 kcal.<br>Tłuszcz - 0g<br>W tym kwasy tłuszczowe nasycone - 0g<br>Węglowodany - 3,5g<br>w tym cukry - 1,5g<br>Białko - 0g<br>Sól - 0g',
		caption: 'Sprzedawany jest w butelkach 0,5 l oraz puszkach 0,5 l. Dostępny jest także w opakowaniach zbiorczych.',
		showExtract: true,
	},
	{
		name: 'Harnaś butelka 0,5l',
		img: bottleImg,
		alcohol: '5,8%',
		ingredients: 'Składniki <span class="line"></span> woda, <span class="accent">słód jęczmienny, jęczmień (J)</span>, syrop z pszenicy i słodu jęczmiennego (S), chmiel. <span class="comment">JS lub SJ – w zależności od kolejności użytych składników.</span>',
		energyValue: 'Wartość odżywcza w 100 ml <span class="line"></span> Wartość energetyczna 179 kJ/43 kcal.<br>Tłuszcz - 0g<br>W tym kwasy tłuszczowe nasycone - 0g<br>Węglowodany - 2,7g<br>w tym cukry - 1g<br>Białko - 0g<br>Sól - 0g',
		caption: 'Sprzedawany jest w butelkach 0,5 l oraz puszkach 0,5 l. Dostępny jest także w opakowaniach zbiorczych.',
		showExtract: true,
	},
	{
		name: 'Harnaś puszka 0,5l',
		img: tinImg,
		alcohol: '5,8%',
		ingredients: 'Składniki <span class="line"></span> woda, <span class="accent">słód jęczmienny, jęczmień (J)</span>, syrop z pszenicy i słodu jęczmiennego (S), chmiel. <span class="comment">JS lub SJ – w zależności od kolejności użytych składników.</span>',
		energyValue: 'Wartość odżywcza w 100 ml <span class="line"></span> Wartość energetyczna 179 kJ/43 kcal.<br>Tłuszcz - 0g<br>W tym kwasy tłuszczowe nasycone - 0g<br>Węglowodany - 2,7g<br>w tym cukry - 1g<br>Białko - 0g<br>Sól - 0g',
		caption: 'Sprzedawany jest w butelkach 0,5 l oraz puszkach 0,5 l. Dostępny jest także w opakowaniach zbiorczych.',
		showExtract: true,
	},
	{
		name: 'Harnaś 4&#8209;pak',
		img: fourPackImg,
		alcohol: '5,8%',
		ingredients: 'Składniki <span class="line"></span> woda, <span class="accent">słód jęczmienny, jęczmień (J)</span>, syrop z pszenicy i słodu jęczmiennego (S), chmiel. <span class="comment">JS lub SJ – w zależności od kolejności użytych składników.</span>',
		energyValue: 'Wartość odżywcza w 100 ml <span class="line"></span> Wartość energetyczna 179 kJ/43 kcal.<br>Tłuszcz - 0g<br>W tym kwasy tłuszczowe nasycone - 0g<br>Węglowodany - 2,7g<br>w tym cukry - 1g<br>Białko - 0g<br>Sól - 0g',
		caption: 'Sprzedawany jest w butelkach 0,5 l oraz puszkach 0,5 l. Dostępny jest także w opakowaniach zbiorczych.',
		showExtract: true,
	},
	{
		name: 'Harnaś 6&#8209;pak',
		img: sixPackImg,
		alcohol: '5,8%',
		ingredients: 'Składniki <span class="line"></span> woda, <span class="accent">słód jęczmienny, jęczmień (J)</span>, syrop z pszenicy i słodu jęczmiennego (S), chmiel. <span class="comment">JS lub SJ – w zależności od kolejności użytych składników.</span>',
		energyValue: 'Wartość odżywcza w 100 ml <span class="line"></span> Wartość energetyczna 179 kJ/43 kcal.<br>Tłuszcz - 0g<br>W tym kwasy tłuszczowe nasycone - 0g<br>Węglowodany - 2,7g<br>w tym cukry - 1g<br>Białko - 0g<br>Sól - 0g',
		caption: 'Sprzedawany jest w butelkach 0,5 l oraz puszkach 0,5 l. Dostępny jest także w opakowaniach zbiorczych.',
		showExtract: true,
	},
	{
		name: 'Harnaś 8&#8209;pak',
		img: eightPackImg,
		alcohol: '5,8%',
		ingredients: 'Składniki <span class="line"></span> woda, <span class="accent">słód jęczmienny, jęczmień (J)</span>, syrop z pszenicy i słodu jęczmiennego (S), chmiel. <span class="comment">JS lub SJ – w zależności od kolejności użytych składników.</span>',
		energyValue: 'Wartość odżywcza w 100 ml <span class="line"></span> Wartość energetyczna 179 kJ/43 kcal.<br>Tłuszcz - 0g<br>W tym kwasy tłuszczowe nasycone - 0g<br>Węglowodany - 2,7g<br>w tym cukry - 1g<br>Białko - 0g<br>Sól - 0g',
		caption: 'Sprzedawany jest w butelkach 0,5 l oraz puszkach 0,5 l. Dostępny jest także w opakowaniach zbiorczych.',
		showExtract: true,
	},
	{
		name: 'Harnaś 12&#8209;pak',
		img: twelvePackImg,
		alcohol: '5,8%',
		ingredients: 'Składniki <span class="line"></span> woda, <span class="accent">słód jęczmienny, jęczmień (J)</span>, syrop z pszenicy i słodu jęczmiennego (S), chmiel. <span class="comment">JS lub SJ – w zależności od kolejności użytych składników.</span>',
		energyValue: 'Wartość odżywcza w 100 ml <span class="line"></span> Wartość energetyczna 179 kJ/43 kcal.<br>Tłuszcz - 0g<br>W tym kwasy tłuszczowe nasycone - 0g<br>Węglowodany - 2,7g<br>w tym cukry - 1g<br>Białko - 0g<br>Sól - 0g',
		caption: 'Sprzedawany jest w butelkach 0,5 l oraz puszkach 0,5 l. Dostępny jest także w opakowaniach zbiorczych.',
		showExtract: true,
	},
];
