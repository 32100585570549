// eslint-disable-next-line import/no-extraneous-dependencies
import $ from 'jquery';
import { setCookie, getCookie } from './includes/cookie';

const Gate = {

	onReady() {

		if ( getCookie( 'isAdult' ) !== 'yes' && window.location.pathname !== '/bramka/' ) {

			localStorage.setItem( 'url', window.location.href );

			window.location = '/bramka';

		}

		const getUrl = localStorage.getItem( 'url' );

		const element = document.querySelector( '.gate-answer-yes' );
		if ( element ) {

			element.addEventListener( 'click', function( e ) {

				e.preventDefault();

				setCookie( 'isAdult', 'yes', ( 1 / 96 ) );

				if ( getCookie( 'isAdult' ) === 'yes' ) {

					if ( getUrl === null ) {

						window.location = '/';

					} else if ( getUrl === window.location.href ) {

						window.location = '/';

					} else {

						window.location = getUrl;

					}

				}

			} );

		}

		const gateNo = document.querySelector( '.gate-answer-no' );
		if ( gateNo ) {

			gateNo.addEventListener( 'click', function( e ) {

				e.preventDefault();

				setCookie( 'isAdult', 'no', 1 / 96 );

			} );

		}

	},
};

$( document ).ready( Gate.onReady );
