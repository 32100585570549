import $ from 'jquery';

const QA = {
	onReady() {

		$( '.qa-item' ).on( 'click', function() {

			const $curr = $( this );

			$curr.toggleClass( 'is-active' );

		} );

		$( '.qa-item a' ).on( 'click', function( e ) {

			e.stopPropagation();

		} );

	},
};

$( document ).ready( QA.onReady );
