import $ from 'jquery';

import { productsSlider } from './includes/products-slider';

const Products = {
	onReady() {

		productsSlider();

	},
};

$( document ).ready( Products.onReady );
