// eslint-disable-next-line import/no-extraneous-dependencies
import qs from 'qs';
import $ from 'jquery';

import { setURL } from './includes/url';
import { hamburger } from './includes/hamburger';
import { navigationActiveClass } from './includes/navigation';

import './products';
import './promo';
import './gate';
import './communes';
import './osps';
import './qa';
import './ourbeers';

export const removePolishCharacters = ( inputText ) => {

	const polishCharacters = {
		ą: 'a', ć: 'c', ę: 'e', ł: 'l', ń: 'n',
		ó: 'o', ś: 's', ź: 'z', ż: 'z',
		Ą: 'A', Ć: 'C', Ę: 'E', Ł: 'L', Ń: 'N',
		Ó: 'O', Ś: 'S', Ź: 'Z', Ż: 'Z',
	};

	return inputText.replace( /[ąćęłńóśźżĄĆĘŁŃÓŚŹŻ]/g, ( char ) => polishCharacters[ char ] || char );

}

const common = ( () => {

	return {
		init: () => {

			window.addEventListener( 'load', () => {

				setURL();
				hamburger();
				navigationActiveClass();
				handleSelectListeners();
				OspVote();
				FilterOSPList();
				RegisterOspForm();
				ThankYouPage();
				handleEmailInputReplacement();

			} );

		},
	};

} )();

const ThankYouPage = () => {

	const $wrapper = $( '.thankYouPageWrapper' );
	const urlParams = new URLSearchParams( window.location.search );
	const a = urlParams.get( 'a' );
	const d = urlParams.get( 'd' );

	if ( a && d ) {

		$wrapper.find( '.thankYouRegister' ).show();
		$wrapper.find( '.thankYouVote' ).hide();

	} else {

		$wrapper.find( '.thankYouRegister' ).hide();
		$wrapper.find( '.thankYouVote' ).show();

	}

}

const handleEmailInputReplacement = () => {

	$( '.applicationEmailInput' ).on( 'change', function () {

		let val = $( this ).val();
		val = val.replace( /\+/g, '' );

		$( this ).val( val );

	} )

}

const handleSelectListeners = () => {

	$( '#voivodeship' ).on( 'change', function () {

		const communityId = $( this ).val();
		const isVoting = $( this ).attr( 'data-voting' ) ?? false;
		const requestData = {
			action: 'custom_action',
			wp_action: 'getCommunitiesByParent',
			data: {
				communityId: communityId,
				vote: isVoting,
			},
		};

		fetch( '/wp-admin/admin-ajax.php', {
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			body: qs.stringify( requestData ),
		} ).then( ( response ) => {

			response.json().then( ( res ) => {

				const $commune = $( '#commune' );

				$commune.empty();
				$commune.append( $( '<option>', {
					value: '',
					text: 'Wybierz',
				} ) );

				$.each( res.data.list, function ( index, option ) {

					$commune.append( $( '<option>', {
						value: option.id,
						text: option.name,
					} ) );

				} );

			} )

		} )

	} )

	$( '#commune' ).on( 'change', function () {

		const communityId = $( this ).val();
		const isVoting = $( this ).attr( 'data-voting' ) ?? false;

		const requestData = {
			action: 'custom_action',
			wp_action: 'getOspByCommunity',
			data: {
				communityId: communityId,
				vote: isVoting,
			},
		};

		fetch( '/wp-admin/admin-ajax.php', {
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			body: qs.stringify( requestData ),
		} ).then( ( response ) => {

			response.json().then( ( res ) => {

				const $osp = $( '#osp' );

				$osp.empty();
				$osp.append( $( '<option>', {
					value: '',
					text: 'Wybierz',
				} ) );

				$.each( res.data.list, function ( index, option ) {

					$osp.append( $( '<option>', {
						value: option.id,
						text: option.name,
					} ) );

				} );

			} )

		} )

	} )

	$( '#osp' ).on( 'change', function () {

		const ospId = $( this ).val();

		const requestData = {
			action: 'custom_action',
			wp_action: 'getOspDetails',
			data: {
				id: ospId,
			},
		};
		fetch( '/wp-admin/admin-ajax.php', {
			method: 'POST',
			headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
			body: qs.stringify( requestData ),
		} ).then( ( response ) => {

			response.json().then( ( res ) => {

				const $osp = $( '#selectedOspText' );
				$osp.html( `<span>Wybrana jednostka:</span><br/>${ res.data.address }` );

			} )

		} )

	} )

}

const RegisterOspForm = () => {

	const $form = $( '#applicationRegisterOspFrom' );

	if ( ! ( $form ).length ) {

		return false;

	}

	$form.on( 'submit', function ( e ) {

		e.preventDefault();

		// Execute reCAPTCHA
		grecaptcha.ready( function () {

			grecaptcha.execute( '6LdhZdopAAAAANRS5OdEJs1TWhwMUD-F7i8FrFXh', { action: 'validate_captcha' } )
				.then( function ( token ) {

					document.getElementById( 'g-recaptcha-response' ).value = token;

					// Check if token is available
					if ( ! token ) {

						console.error( 'reCAPTCHA token is not available.' );
						return;

					}

					const voivodeship = $( '#voivodeship' );
					const province = $( '#commune' );
					const osp = $( '#osp' );
					const name = $( '#name' );
					const email = $( '#email' );

					if ( ! voivodeship.val() || ! province.val() || ! osp.val() || ! name.val() || ! email.val() ) {

						return false;

					}

					const formData = $( '#applicationRegisterOspFrom' ).serializeArray().reduce( function ( obj, item ) {

						obj[ item.name ] = ( item.value );
						return obj;

					}, {} );

					const requestData = {
						action: 'custom_action',
						wp_action: 'registerOsp',
						data: formData,
					};
					fetch( '/wp-admin/admin-ajax.php', {
						method: 'POST',
						headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
						body: qs.stringify( requestData ),
					} ).then( ( response ) => {

						response.json().then( ( res ) => {

							// alert( res?.data?.message ?? 'Zapisano. Wyświetl komunikat' );
							$( '.formThanks' ).show();
							$( '.formActive' ).hide();
							window.scrollTo( { top: 50, behavior: 'smooth' } );

							// const $osp = $( '#selectedOspText' );
							//
							// $osp.html( `Wybrana jednostka: ${ res.data.address }` );

						} )

					} )
					return true;

				} );

		} );

	} );

}

// eslint-disable-next-line
const OspVote = () => {
	const $form = $( '#applicationVoteOspFrom' );

	if ( ! ( $form ).length ) {

		return false;

	}

	$form.on( 'submit', function ( e ) {

		e.preventDefault();

		// Execute reCAPTCHA
		grecaptcha.ready( function () {

			grecaptcha.execute( '6LdhZdopAAAAANRS5OdEJs1TWhwMUD-F7i8FrFXh', { action: 'validate_captcha' } )
				.then( function ( token ) {

					console.log( token );
					document.getElementById( 'g-recaptcha-response' ).value = token;

					// Check if token is available
					if ( ! token ) {

						console.error( 'reCAPTCHA token is not available.' );

					}

					const voivodeship = $( '#voivodeship' );
					const province = $( '#commune' );
					const osp = $( '#osp' );
					const messageContainer = $( '.messageContainer' );
					if ( ! voivodeship.val() || ! province.val() || ! osp.val() ) {

						messageContainer.html( 'Wybierz województwo, powiat oraz jednostkę, aby oddać głos.' );
						showNotificationAfterVote();

						window.dataLayer.push( {
							event: 'vote_fail',
							region: removePolishCharacters( voivodeship.find( ':selected' ).text() ),
							district: removePolishCharacters( province.find( ':selected' ).text() ),
							osp: '',
						} );

						return false;

					}

					const formData = $form.serializeArray().reduce( function ( obj, item ) {

						obj[ item.name ] = ( item.value );
						console.log( obj );
						return obj;

					}, {} );

					const requestData = {
						action: 'custom_action',
						wp_action: 'voteOsp',
						data: formData,
					};

					fetch( '/wp-admin/admin-ajax.php', {
						method: 'POST',
						headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
						body: qs.stringify( requestData ),
					} ).then( ( response ) => {

						response.json().then( ( res ) => {

							messageContainer.html( '' );
							const voteStatus = res.success ? 'success' : 'glos_juz_oddany';
							if ( ! res.success ) {

								if ( res.data.status === 402 ) {

									messageContainer.html( res.data.message );

								} else {

									$( '.messageImage.-restriction' ).show();

								}
								$( '.submitGroup' ).hide();

								window.dataLayer.push( {
									event: 'vote_confirmation',
									vote_status: voteStatus,
									region: removePolishCharacters( voivodeship.find( ':selected' ).text() ),
									district: removePolishCharacters( province.find( ':selected' ).text() ),
									osp: removePolishCharacters( osp.find( ':selected' ).text() ),
								} );

							} else {

								//messageContainer.html( res.data.message );
								$( '.messageImage.-email' ).show();
								$( '.submitGroup' ).hide();

								window.dataLayer.push( {
									event: 'vote_confirmation',
									vote_status: voteStatus,
									region: removePolishCharacters( voivodeship.find( ':selected' ).text() ),
									district: removePolishCharacters( province.find( ':selected' ).text() ),
									osp: removePolishCharacters( osp.find( ':selected' ).text() ),
								} );

								// window.location.href = '/dziekujemy/';

							}

						} )

					} )

				} )
			return true;

		} )

	} )

	const messageContainer = $( '.messageContainer' );
	const selectedVoivodeship = null;
	const selectedProvince = null;
	const selectedOsp = null;
	const selectedOspName = null;
	const isVoteStartRegistered = false;

	const showNotificationAfterVote = () => {

		messageContainer.show();

	}

}

const FilterOSPList = () => {

	$( '#filterOspListInput' ).on( 'input change', function () {

		const valueInput = $( this ).val().toLowerCase().trim();

		// Send AJAX request to filter OSP items
		$.ajax( {
			url: '/wp-admin/admin-ajax.php', // WordPress AJAX URL
			type: 'POST',
			data: {
				action: 'filter_osp_items',
				name: valueInput,
			},
			success: function ( response ) {

				if ( response.success ) {

					const ospItems = response.data;

					// Clear current OSP items
					$( '.ospList' ).empty();

					// Append filtered OSP items to the list
					ospItems.forEach( ( item ) => {

						// Format the address
						const addressLines = item.address.split( '\n' );
						addressLines.shift(); // Remove the first line

						const formattedAddress = addressLines.join( '' );

						// Generate HTML based on the template
						const html = `
                            <div class="item ospItem" data-name="${ item.title }">
                                <p class="num">${ item.rank }.</p>
                                <p class="osp"><strong>${ item.votes } pkt.</strong> - OSP ${ item.title }</p>
                                <p class="adr">${ formattedAddress }</p>
                            </div>
                        `;
						$( '.ospList' ).append( html );

					} );

				} else {

					// Handle no results found
					$( '.ospList' ).html( '<p>No OSP items found</p>' );

				}

			},
			error: function () {

				// Handle AJAX error
				console.error( 'AJAX request failed' );

			},
		} );

	} );

};

common.init();
