export const hamburger = () => {

	const hamburgerElement = document.querySelector( '.hamburger' );
	const navigation = document.querySelector( '.navigation' );

	if ( hamburgerElement ) {

		hamburgerElement.addEventListener( 'click', function() {

			this.classList.toggle( 'is-active' );
			navigation.classList.toggle( 'is-visible' );

		} );

	}

};
