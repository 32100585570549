const communes = {
	dolnośląskie: [ 'bolesławiecki', 'dzierżoniowski', 'głogowski', 'górowski', 'jaworski', 'jeleniogórski', 'kamiennogórski', 'kłodzki', 'legnicki', 'lubański', 'lubiński', 'lwówecki', 'milicki', 'oleśnicki', 'oławski', 'polkowicki', 'strzeliński', 'średzki-śląski', 'świdnicki', 'trzebnicki', 'wałbrzyski', 'wołowski', 'wrocławski', 'ząbkowicki', 'zgorzelecki', 'złotoryjski' ],
	'kujawsko-pomorskie': [ 'aleksandrowski', 'brodnicki', 'bydgoskigrodzki', 'bydgoskiziemski', 'chełmiński', 'golubsko-dobrzyński', 'grudziądzki', 'inowrocławski', 'lipnowski', 'mogileński', 'nakielski', 'radziejowski', 'rypiński', 'sępoleński', 'świecki', 'toruński', 'tucholski', 'wąbrzeski', 'włocławski', 'żniński' ],
	lubelskie: [ 'bialski', 'biłgorajski', 'chełmski', 'hrubieszowski', 'janowski', 'krasnostawski', 'kraśnicki', 'lubartowski', 'lubelski', 'łęczyński', 'łukowski', 'opolski-lubelski', 'parczewski', 'puławski', 'radzyński', 'rycki', 'tomaszowski', 'włodawski', 'zamojski' ],
	lubuskie: [ 'gorzowski', 'krośnieński', 'międzyrzecki', 'nowosolski', 'słubicki', 'strzelecko-drezdenecki', 'sulęciński', 'świebodziński', 'wschowski', 'ZielonaGóra', 'żagański', 'żarski' ],
	łódzkie: [ 'bełchatowski', 'brzeziński', 'kutnowski', 'łaski', 'łęczycki', 'łowicki', 'łódzki', 'łódzki-wschodni', 'opoczyński', 'pabianicki', 'pajęczański', 'piotrkowski', 'poddębicki', 'radomszczański', 'rawski', 'sieradzki', 'skierniewicki', 'tomaszowski-mazowiecki', 'wieluński', 'wieruszowski', 'zduńskowolski', 'zgierski' ],
	małopolskie: [ 'bocheński', 'brzeski', 'chrzanowski', 'dąbrowski-tarnowski', 'gorlicki', 'krakowski-grodzki', 'krakowski-ziemski', 'limanowski', 'miechowski', 'myślenicki', 'nowosądecki', 'nowotarski', 'olkuski', 'oświęcimski', 'proszowicki', 'suski', 'tarnowski', 'tatrzański', 'wadowicki', 'wielicki' ],
	mazowieckie: [ 'białobrzeski', 'ciechanowski', 'garwoliński', 'gostyniński', 'grodziski-mazowiecki', 'grójecki', 'kozienicki', 'legionowski', 'lipski', 'łosicki', 'makowski-mazowiecki', 'miński-mazowiecki', 'mławski', 'nowodworski-mazowiecki', 'ostrołęcki', 'ostrowski-mazowiecki', 'otwocki', 'piaseczyński', 'płocki', 'płoński', 'pruszkowski', 'przasnyski', 'przysuski', 'pułtuski', 'radomski', 'siedlecki', 'sierpecki', 'sochaczewski', 'sokołowski', 'szydłowiecki', 'Warszawa-Zachód', 'warszawski', 'węgrowski', 'wołomiński', 'wyszkowski', 'zwoleński', 'żuromiński', 'żyrardowski' ],
	opolskie: [ 'głubczycki', 'kędzierzyńsko-kozielski', 'kluczborski', 'krapkowicki', 'namysłowski', 'nyski', 'oleski', 'opolskigrodzki', 'opolski-ziemski', 'prudnicki', 'strzelecki' ],
	podkarpackie: [ 'bieszczadzki', 'brzozowski', 'dębicki', 'jarosławski', 'jasielski', 'kolbuszowski', 'krośnieński', 'leski', 'leżajski', 'lubaczowski', 'łańcucki', 'mielecki', 'niżański', 'przemyski', 'przeworski', 'ropczycko-sędziszowski', 'rzeszowski', 'sanocki', 'stalowowolski', 'strzyżowski', 'Tarnobrzeggrodzki', 'tarnobrzeski-ziemski' ],
	podlaskie: [ 'augustowski', 'białostocki', 'bielskipodlaski', 'grajewski', 'hajnowski', 'kolneński', 'łomżyński', 'moniecki', 'sejneński', 'siemiatycki', 'sokólski', 'suwalski', 'wysokomazowiecki', 'zambrowski' ],
	pomorskie: [ 'Bytów', 'Chojnice', 'Człuchów', 'Gdańskm.', 'Gdański', 'Gdyniam.', 'Kartuzy', 'Kościerzyna', 'Kwidzyn', 'Lębork', 'Malbork', 'NowyDwórGd.', 'Puck', 'Słupsk', 'StarogardGd.', 'Sztum', 'Tczew', 'Wejherowo' ],
	śląskie: [ 'będziński', 'bielski-ziemski', 'Bielsko-Biała-grodzki', 'bieruńsko-lędziński', 'Bytom-grodzki', 'cieszyński', 'Częstochowa-grodzki', 'częstochowski-ziemski', 'DąbrowaGórnicza', 'gliwicki', 'JastrzębieZdrój-grodzki', 'Jaworzno-grodzki', 'Katowice-grodzki', 'kłobucki', 'lubliniecki', 'mikołowski', 'Mysłowice-grodzki', 'myszkowski', 'PiekaryŚląskie', 'pszczyński', 'raciborski', 'rybnicki-ziemski', 'Rybnik-grodzki', 'Sosnowiecki', 'Świętochłowicki', 'tarnogórski', 'wodzisławski', 'Zabrze-grodzki', 'zawierciański', 'Żory-grodzki', 'żywiecki' ],
	świętokrzyskie: [ 'buski', 'jędrzejowski', 'kazimierski', 'kielecki', 'konecki', 'opatowski', 'ostrowiecki', 'pińczowski', 'sandomierski', 'skarżyski', 'starachowicki', 'staszowski', 'włoszczowski' ],
	'warmińsko-mazurskie': [ 'Bartoszyce', 'Braniewo', 'Działdowo', 'Elbląg', 'Ełk', 'Giżycko', 'Gołdap', 'Iława', 'Kętrzyn', 'LidzbarkWarmiński', 'Mrągowo', 'Nidzica', 'NoweMiastoLubawskie', 'Olecko', 'Olsztyn-ziemski', 'Ostróda', 'Pisz', 'Szczytno', 'Węgorzewo' ],
	wielkopolskie: [ 'chodzieski', 'czarnkowsko-trzcianecki', 'gnieźnieński', 'gostyński', 'grodziski', 'jarociński', 'kaliski', 'kępiński', 'kolski', 'Koningrodzki', 'koniński-ziemski', 'kościański', 'krotoszyński', 'leszczyński', 'międzychodzki', 'nowotomyski', 'obornicki', 'ostrowski', 'ostrzeszowski', 'pilski', 'pleszewski', 'poznański', 'rawicki', 'słupecki', 'szamotulski', 'średzki', 'śremski', 'turecki', 'wągrowiecki', 'wolsztyński', 'wrzesiński', 'złotowski' ],
	zachodniopomorskie: [ 'białogardzki', 'Choszczeński', 'drawski', 'goleniowski', 'gryficki', 'gryfiński', 'kamieński', 'kołobrzeski', 'koszaliński', 'łobeski', 'myśliborski', 'policki', 'pyrzycki', 'sławeński', 'stargardzki', 'Szczecin', 'szczecinecki', 'świdwiński', 'Świnoujście', 'wałecki' ],
};
