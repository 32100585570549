import $ from 'jquery';

export const navigationActiveClass = () => {

	// const pageId = $( 'body' ).attr( 'id' );
	const pageId = window.location.pathname;

	$( '.navigation-link' ).each( function( index, element ) {

		const navigationId = $( element ).attr( 'data-id' );

		if ( pageId === navigationId ) {

			$( element ).addClass( 'is-active' );

		}

	} );

};
