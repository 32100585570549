import $ from 'jquery';
import 'jquery-parallax.js';

const Promo = {
	onReady() {

		$( '.startFinalVideo' ).on( 'click', function() {

			$( '.teaserWrapper' ).hide()
			$( '.videoContainer' ).removeClass( '-stopped' );
			$( '.finalVideo' ).trigger( 'play' );

		} );

	},
};

$( document ).ready( Promo.onReady );

